import React, { useEffect, useState } from 'react';
import * as QUESTIONS from '../common/questions';
import * as REQUEST from '../common/request';
import * as Utils from '../common/utils';

import IMG_THANKS from '../common/images/thanks.png';
import IMG_NOTFOUND from '../common/images/404.png';
import IMG_SELECTED from '../common/images/selected.png';

const PageModel = props => {
	const prjName = props.match.params.prj;
	const QUESTION = QUESTIONS[prjName] || {};

	const [ page, setPage ] = useState(1);
	const tabPage = () => {
		setPage(page + 1);
		document.body.scrollTop=document.documentElement.scrollTop = 0;

		answerHelper.initial();
	};

	const [ parts, setParts ] = useState([]);
	const getParts = () => {
		const parts = QUESTION.parts || [];
		setParts(parts);
	};
	useEffect(() => {
		getParts();
	}, [123]);

	const [ answers, setAnswers ] = useState([]);
	const checkAnswers = (question = '', answer = '', multi = false) => {
		let flag = false;
		answers.map(ans => {
			if(question && answer && ans.question == question && (
				(multi == false && ans.answer == answer) ||
				(multi == true && ans.answer.includes(answer))
			)) {
				flag = true;
			}
		});
		return flag;
	};
	const updateAnswers = (question = '', answer = '', qid = null) => {
		if(!question || !answer || !qid) return;

		let ok = false;
		answers.map(ans => {
			if(ans.question == question) {
				ans.answer = answer;
				ok = true;
			}
		});
		if(!ok) {
			answers.push({ qid, question, answer });
		}

		setAnswers([...answers]);
		answerHelper.tempSave([...answers]);
	};

	const submit = async (callback) => {
		const result = await REQUEST.post({
			url: '/question/submit',
			data: {
				project: prjName,
				answers
			}
		});
		if(!result) return;

		answerHelper.tempSave([]);
		if(callback && typeof callback == 'function'){
			callback();
		}
	};

	const answerHelper = {
		tempSave: (answers) => {
			Utils.localCache({
				key: prjName + '-temp',
				value: JSON.stringify(answers),
				type: 1
			})
		},
		initial: () => {
			let answers = Utils.localCache({
				key: prjName + '-temp',
				type: 1
			});
			answers = answers && typeof answers == 'string' ? JSON.parse(answers) : [];
			setAnswers([...answers]);
		}
	};

	if(!prjName || !QUESTION || !QUESTION.title) {
		return (
			<div style={{ height: window.innerHeight, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
				<img src={IMG_NOTFOUND} style={{ width: 66 }} />
				<span style={{ fontSize: 28, fontWeight: 900, color: '#999999' }}>404</span>
			</div>
		)
	}

	document.title = `${QUESTION.title}调查问卷`;

	return (
		<div style={{ fontSize: 14, fontFamily: 'sans-serif' }}>
			{/*<!-- page 1 · Welcome-->*/}
			{
				page == 1 && <div style={{}}>
					<div style={{ marginTop: 50, marginBottom: 30, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						<span style={{ fontWeight: 900, fontSize: QUESTION.titleSize || 20 }}>{QUESTION.title}</span>
						<span style={{ fontSize: 16, paddingTop: 8 }}>{QUESTION.subTitle || '调查问卷'}</span>
					</div>
					<div className="intro" style={{ paddingLeft: 20, paddingRight: 20, color: '#737373', lineHeight: 1.9, textAlign: 'justify', whiteSpace: 'pre-wrap', textIndent: '2em' }}>
						{
							QUESTION.intro && QUESTION.intro.length > 0 && QUESTION.intro.map((txt, idx) => {
								return <p key={idx} style={{ textAlign: 'justify' }}>{txt}</p>
							})
						}
					</div>
					<div style={{ fontSize: 16, width: 220, height: 44, marginRight: 'auto', marginLeft: 'auto', marginTop: 60, alignItems: 'center', display: 'flex', justifyContent: 'center', borderRadius: 8, backgroundColor: '#009688', color: '#ffffff' }} onClick={tabPage}>开始作答</div>
				</div>
			}

			{
				parts && parts.length > 0 && parts.map((partItem, partIndex) => {
					if(partIndex + 2 != page) {
						return null;
					}
					return (
						<div style={{ padding: 20 }} key={partIndex}>
							<div style={{ marginBottom: 20, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
								<span style={{ fontWeight: 900, fontSize: 18 }}>{partItem.title}</span>
								{ partItem.intro && <span style={{ fontSize: 14, paddingTop: 8, color: '#737373', textAlign: 'justify', lineHeight: 1.8 }}>{partItem.intro}</span> }
							</div>
							<div style={{}}>
								{
									partItem.questions.map((item, index) => {
										if(item.listType == 1) {
											return (
												<div style={{ fontSize: 16, marginBottom: 20 }} key={index}>
													<span>{index + 1}、{item.question}</span>
													<div style={{ overflow: 'hidden', marginTop: 10 }}>
														{
															item.answers.map((itm, idx) => {
																const actStyle = checkAnswers(item.question, itm.text) ? { borderColor: '#009688', backgroundColor: '#009688', backgroundImage: `url(${IMG_SELECTED})` } : {};
																return (
																	<div style={{ float: 'left', display: 'flex', alignItems: 'center', marginRight: 36, marginBottom: 6 }} key={idx} onClick={() => {
																		updateAnswers(item.question, itm.text, item.id);
																	}}>
																		<i style={{ display: 'block', marginRight: 10,  width: 16, height: 16, borderRadius: 160, borderStyle: 'solid', borderColor: '#dcdcdc', borderWidth: 1, backgroundPosition: '50% 50%', backgroundRepeat: 'no-repeat', backgroundSize: '88%', ...actStyle }} /><span>{itm.text}</span>
																	</div>
																)
															})
														}
													</div>
												</div>
											);
										}else if(item.listType == 2) {
											return (
												<div style={{ fontSize: 16, marginBottom: 28 }} key={index}>
													<span>{index + 1}、{item.question}</span>
													<div style={{ overflow: 'hidden', marginTop: 12, paddingLeft: 24 }}>
														{
															item.answers.map((itm, idx) => {
																let act = false;
																let defaultValue = undefined;
																itm.options.map(option => {
																	if(checkAnswers(`${item.question}|${itm.text}`, option)) {
																		act = true;
																		defaultValue = option;
																	}
																});
																const actStyle = act ? { color: '#333333' } : {};
																return (
																	<div style={{ marginBottom: item.answers.length - 1 <= idx ? 0 : 10, fontSize: 14, alignItems: 'center', display: 'flex' }} key={idx}>
																		<label style={{ display: 'flex', alignItems: 'center' }}>{itm.text}</label>
																		<select defaultValue={defaultValue} style={{ width: 144, display: 'flex', alignItems: 'center', height: 36, fontSize: 14, marginLeft: 16, padding: 8, boxSizing: 'border-box', outline: 'none', border: 'solid #dcdcdc', borderWidth: 1, borderRadius: 4, background: 'transparent', color: '#999999', ...actStyle }} onChange={e => {
																			updateAnswers(`${item.question}|${itm.text}`, e.target.value, item.id);
																		}}>
																			<option value="" disabled selected>请选择</option>
																			{
																				itm.options.map((option, oindex) => <option value={option}>{option}</option>)
																			}
																		</select>
																	</div>
																)
															})
														}
													</div>
												</div>
											);
										}else if(item.listType == 3) {
											return (
												<div style={{ fontSize: 16, marginBottom: 32 }} key={index}>
													<span>{index + 1}、{item.question}</span>
													<div style={{ overflow: 'hidden', marginTop: 10, paddingLeft: 24 }}>
														{
															item.answers.map((itm, idx) => {
																let act1 = false;
																let defaultValue1 = undefined;
																itm.options1.map(option => {
																	if(checkAnswers(`${item.question}|${itm.text}|1`, option)) {
																		act1 = true;
																		defaultValue1 = option;
																	}
																});
																const act1Style = act1 ? { color: '#333333' } : {};

																let act2 = false;
																let defaultValue2 = undefined;
																itm.options2.map(option => {
																	if(checkAnswers(`${item.question}|${itm.text}|2`, option)) {
																		act2 = true;
																		defaultValue2 = option;
																	}
																});
																const act2Style = act2 ? { color: '#333333' } : {};

																const selectStyle = { display: 'flex', alignItems: 'center', fontSize: 14, width: 102, lineHeight: 1, height: 36, marginLeft: 12, padding: 8, boxSizing: 'border-box', outline: 'none', border: 'solid #dcdcdc', borderWidth: 1, borderRadius: 4, background: 'transparent', color: '#999999' };
																return (
																	<div style={{ marginBottom: item.answers.length - 1 <= idx ? 0 : 10, fontSize: 14, display: 'flex', borderBottom: 'solid #dcdcdc', borderWidth: item.answers.length - 1 <= idx ? 0 : 1, paddingTop: 10, paddingBottom: item.answers.length - 1 <= idx ? 0 : 10 }} key={idx}>
																		<span>{itm.text}</span>
																		<select defaultValue={defaultValue1} style={{ ...selectStyle, ...act1Style }} onChange={e => {
																			updateAnswers(`${item.question}|${itm.text}|1`, e.target.value, item.id);
																		}}>
																			<option value="" disabled selected>请选择</option>
																			{
																				itm.options1.map((option, oindex) => <option value={option} key={oindex}>{option}</option>)
																			}
																		</select>
																		<select defaultValue={defaultValue2} style={{ ...selectStyle, ...act2Style }} onChange={e => {
																			updateAnswers(`${item.question}|${itm.text}|2`, e.target.value, item.id);
																		}}>
																			<option value="" disabled selected>请选择</option>
																			{
																				itm.options2.map((option, oindex) => <option value={option} key={oindex}>{option}</option>)
																			}
																		</select>
																	</div>
																)
															})
														}
													</div>
												</div>
											);
										}else if(item.listType == 4) {
											return (
												<div style={{ fontSize: 16, marginBottom: 20 }} key={index}>
													<span>{index + 1}、{item.question}（多选题）</span>
													<div style={{ overflow: 'hidden', marginTop: 10 }}>
														{
															item.answers.map((itm, idx) => {
																const actStyle = checkAnswers(item.question, itm.text, true) ? { borderColor: '#009688', backgroundColor: '#009688', backgroundImage: `url(${IMG_SELECTED})` } : {};
																return (
																	<div style={{ float: 'left', display: 'flex', alignItems: 'center', marginRight: 36, marginBottom: 6 }} key={idx} onClick={() => {
																		let _answer = '';
																		answers.map(ans => {
																			if(ans.question == item.question) {
																				_answer = ans.answer;
																			}
																		});
																		const _answerArr = _answer && _answer.split('/') || [];
																		if(_answerArr.indexOf(itm.text) > -1) {
																			_answerArr.splice(_answerArr.indexOf(itm.text),1);
																		}else{
																			_answerArr.push(itm.text);
																		}
																		_answer = _answerArr.join('/');

																		updateAnswers(item.question, _answer, item.id);
																	}}>
																		<i style={{ display: 'block', marginRight: 10,  width: 16, height: 16, borderRadius: 160, borderStyle: 'solid', borderColor: '#dcdcdc', borderWidth: 1, backgroundPosition: '50% 50%', backgroundRepeat: 'no-repeat', backgroundSize: '88%', ...actStyle }} /><span>{itm.text}</span>
																	</div>
																)
															})
														}
													</div>
												</div>
											);
										}else if(item.listType == 5) {
											let defaultValue = '';
											answers.map(ans => {
												if(ans.question == item.question) {
													defaultValue = ans.answer;
												}
											});
											return (
												<div style={{ fontSize: 16, marginBottom: 20 }} key={index}>
													<span>{index + 1}、{item.question}</span>
													<div style={{ overflow: 'hidden', marginTop: 10, border: 'solid 1px #dcdcdc', borderRadius: 4, position: 'relative', height: 88 }}>
														<textarea style={{ border: 'none', position: 'absolute', width: '100%', height: '100%', padding: 10, boxSizing: 'border-box', outline: 'none' }} defaultValue={defaultValue} onChange={e => {
															console.log('e', e.target.value);
															updateAnswers(item.question, e.target.value, item.id);
														}} />
													</div>
												</div>
											)
										}
									})
								}
							</div>
							<div style={{ fontSize: 16, width: 220, height: 44, marginRight: 'auto', marginLeft: 'auto', marginTop: 30, alignItems: 'center', display: 'flex', justifyContent: 'center', borderRadius: 8, backgroundColor: '#009688', color: '#ffffff' }} onClick={() => {
								if(partIndex == parts.length - 1) {
									submit(tabPage);
								}else{
									tabPage();
								}
							}}>
								{ partIndex == parts.length - 1 ? '完成并提交' : '下一部分' }
							</div>
						</div>
					)
				})
			}

			{/*<!-- page 5 · Thanks-->*/}
			{
				page == parts.length + 2 && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: window.innerHeight }}>
					<img src={IMG_THANKS} style={{ width: 66, marginBottom: 18 }} />
					<span style={{ color: '#333333', fontSize: 18, fontWeight: 900 }}>再次感谢您的配合</span>
				</div>
			}
		</div>
	);
};

export default PageModel;