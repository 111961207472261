/**
 * Created by jimmy on 2020/9/10.
 */

/**
 *
 * @param listType 1 - 单选；2 - 下拉选择；3 - 一题多选项多答案；4 - 多选；5 - 问答
 * @type {{title: string, titleSize: number, intro: string, parts: [*]}}
 */

export const gggcxmshfxpg = {
	title: `上海建科造价项目环境与社会风险评估课题组`,
	titleSize: 16,
	intro: [
		'尊敬的先生/女士：您好！',
		'我们是上海建科造价项目环境与社会风险评估课题组，目前正针对公共工程项目社会风险进行调查。本问卷包括三部分，第一部分是基本信息，第二部分是风险发生概率与影响大小，第三部分是各风险因素的相对重要性判断。本调查采取不记名方式，调查所收集的数据仅用于研究，请您放心填写。您的回答对研究很重要，衷心感谢您抽出宝贵时间完成本问卷！'
	],
	parts: [
		{
			title: `第一部分、基本信息`,
			intro: ``,
			// 问卷问题，listType: 3
			questions: [
				{
					id: 1,
					listType: 1,
					question: '您的性别',
					answers: [
						{ text: '男' }, { text: '女' },
					]
				},
				{
					id: 2,
					listType: 1,
					question: '您的年龄',
					answers: [
						{ text: '25岁以下' }, { text: '25-35岁' }, { text: '36-45岁' }, { text: '46-55岁' }, { text: '56周岁以上' }
					]
				},
				{
					id: 3,
					listType: 1,
					question: '您的教育层次',
					answers: [
						{ text: '高中及以下' }, { text: '大专' }, { text: '本科' }, { text: '研究生及以上' }
					]
				},
				{
					id: 4,
					listType: 1,
					question: '您的职位',
					answers: [
						{ text: '普通员工' }, { text: '文员' }, { text: '工程师' }, { text: '高级工程师' }, { text: '其他' }
					]
				},
				{
					id: 5,
					listType: 1,
					question: '您工作年限',
					answers: [
						{ text: '6个月内' }, { text: '6-12个月' }, { text: '1-2年' }, { text: '2-5年' }, { text: '5年以上' }
					]
				},
				{
					id: 6,
					listType: 1,
					question: '您所参与的项目是否实施了社会风险管理',
					answers: [
						{ text: '是' }, { text: '否' },
					]
				},
				{
					id: 7,
					listType: 1,
					question: '您认为工程项目规划建设过程中是否有必要进行社会风险管理？',
					answers: [
						{ text: '有必要' }, { text: '没必要' },
					]
				}
			]
		},
		{
			title: `第二部分、风险因素评估`,
			intro: `该部分主要评估公共工程项目社会风险发生的可能性与影响程度。社会风险发生的可能性与影响程度均分为五个等级：非常低、比较低、中等、比较高、非常高，请在对应的等级上打√。`,
			// 问卷问题，listType: 2
			questions: [
				{
					id: 8,
					listType: 2,
					question: '项目引起的文化冲突',
					answers: [
						{ text: '风险发生的可能性', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
						{ text: '发生后的影响程度', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
					]
				},
				{
					id: 9,
					listType: 2,
					question: '项目的负面舆论',
					answers: [
						{ text: '风险发生的可能性', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
						{ text: '发生后的影响程度', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
					]
				},
				{
					id: 10,
					listType: 2,
					question: '项目引起的社会冲突',
					answers: [
						{ text: '风险发生的可能性', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
						{ text: '发生后的影响程度', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
					]
				},
				{
					id: 11,
					listType: 2,
					question: '当地居民的反对',
					answers: [
						{ text: '风险发生的可能性', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
						{ text: '发生后的影响程度', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
					]
				},
				{
					id: 12,
					listType: 2,
					question: '项目所在地可能引发的交通风险',
					answers: [
						{ text: '风险发生的可能性', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
						{ text: '发生后的影响程度', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
					]
				},
				{
					id: 13,
					listType: 2,
					question: '征地/拆迁补偿不满意',
					answers: [
						{ text: '风险发生的可能性', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
						{ text: '发生后的影响程度', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
					]
				},
				{
					id: 14,
					listType: 2,
					question: '安置补偿不到位',
					answers: [
						{ text: '风险发生的可能性', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
						{ text: '发生后的影响程度', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
					]
				},
				{
					id: 15,
					listType: 2,
					question: '对生产经营的影响',
					answers: [
						{ text: '风险发生的可能性', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
						{ text: '发生后的影响程度', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
					]
				},
				{
					id: 16,
					listType: 2,
					question: '项目对当地居民收入影响',
					answers: [
						{ text: '风险发生的可能性', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
						{ text: '发生后的影响程度', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
					]
				},
				{
					id: 17,
					listType: 2,
					question: '相关产品或者服务价格上涨',
					answers: [
						{ text: '风险发生的可能性', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
						{ text: '发生后的影响程度', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
					]
				},
				{
					id: 18,
					listType: 2,
					question: '对周边土地、房屋价格的影响',
					answers: [
						{ text: '风险发生的可能性', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
						{ text: '发生后的影响程度', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
					]
				},
				{
					id: 19,
					listType: 2,
					question: '项目造成的噪声、粉尘、辐射等环境污染',
					answers: [
						{ text: '风险发生的可能性', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
						{ text: '发生后的影响程度', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
					]
				},
				{
					id: 20,
					listType: 2,
					question: '项目引起土水土流失和绿地面积减少',
					answers: [
						{ text: '风险发生的可能性', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
						{ text: '发生后的影响程度', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
					]
				},
				{
					id: 21,
					listType: 2,
					question: '项目引起的人文景观破坏',
					answers: [
						{ text: '风险发生的可能性', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
						{ text: '发生后的影响程度', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
					]
				},
				{
					id: 22,
					listType: 2,
					question: '决策不合理',
					answers: [
						{ text: '风险发生的可能性', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
						{ text: '发生后的影响程度', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
					]
				},
				{
					id: 23,
					listType: 2,
					question: '项目信息公开不及时且不透明',
					answers: [
						{ text: '风险发生的可能性', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
						{ text: '发生后的影响程度', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
					]
				},
				{
					id: 24,
					listType: 2,
					question: '政府诚信缺失',
					answers: [
						{ text: '风险发生的可能性', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
						{ text: '发生后的影响程度', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
					]
				},
				{
					id: 25,
					listType: 2,
					question: '公民参与不足',
					answers: [
						{ text: '风险发生的可能性', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
						{ text: '发生后的影响程度', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
					]
				},
				{
					id: 26,
					listType: 2,
					question: '问责机制缺乏',
					answers: [
						{ text: '风险发生的可能性', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
						{ text: '发生后的影响程度', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
					]
				},
				{
					id: 27,
					listType: 2,
					question: '社会风险管理体系不健全',
					answers: [
						{ text: '风险发生的可能性', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
						{ text: '发生后的影响程度', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
					]
				},
				{
					id: 28,
					listType: 2,
					question: '规划不恰当',
					answers: [
						{ text: '风险发生的可能性', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
						{ text: '发生后的影响程度', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
					]
				},
				{
					id: 29,
					listType: 2,
					question: '设计不合理',
					answers: [
						{ text: '风险发生的可能性', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
						{ text: '发生后的影响程度', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
					]
				},
				{
					id: 30,
					listType: 2,
					question: '进度紧张',
					answers: [
						{ text: '风险发生的可能性', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
						{ text: '发生后的影响程度', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
					]
				},
				{
					id: 31,
					listType: 2,
					question: '施工经验和技术不足',
					answers: [
						{ text: '风险发生的可能性', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
						{ text: '发生后的影响程度', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
					]
				},
				{
					id: 32,
					listType: 2,
					question: '安全事故发生率高',
					answers: [
						{ text: '风险发生的可能性', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
						{ text: '发生后的影响程度', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
					]
				},
				{
					id: 33,
					listType: 2,
					question: '工程质量不合格',
					answers: [
						{ text: '风险发生的可能性', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
						{ text: '发生后的影响程度', options: [ '非常低', '比较低', '中等', '比较高', '非常高' ] },
					]
				}
			]
		},
		{
			title: `第三部分、风险因素相对重要性判断`,
			intro: `判断提示：对Bi和Bj两个风险因素进行判断，分为两步：第一步，对Bi和Bj两个因素的重要性进行判断，在重要性判断列中，如果认为Bi比Bj重要，则选“前者重要”；如果认为Bi和Bj同等重要，则选“一样重要”；如果认为Bj比Bi重要，则选“后者重要”。
第二步，对Bi和Bj两个因素的重要性程度进行判断，在重要程度判断列中选择对应选项。`,
			// 问卷问题，listType: 1
			questions: [
				{
					id: 34,
					listType: 3,
					question: '社会因素重要性与重要程度判断',
					answers: [
						{ text: `文化冲突 VS 负面舆论`, options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: `文化冲突 VS 社会冲突`, options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: `文化冲突 VS 居民反对`, options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: `文化冲突 VS 交通风险`, options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: `负面舆论 VS 社会冲突`, options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: `负面舆论 VS 居民反对`, options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: `负面舆论 VS 交通风险`, options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: `社会冲突 VS 居民反对`, options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: `社会冲突 VS 交通风险`, options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: `居民反对 VS 交通风险`, options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
					]
				},
				{
					id: 35,
					listType: 3,
					question: '经济因素重要性与重要程度判断',
					answers: [
						{ text: '征拆补偿 VS 安置补偿', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '征拆补偿 VS 生产影响', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '征拆补偿 VS 居民收入', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '征拆补偿 VS 服务价格', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '征拆补偿 VS 土地价格', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '安置补偿 VS 生产影响', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '安置补偿 VS 居民收入', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '安置补偿 VS 服务价格', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '安置补偿 VS 土地价格', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '生产影响 VS 居民收入', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '生产影响 VS 服务价格', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '生产影响 VS 土地价格', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '居民收入 VS 服务价格', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '居民收入 VS 土地价格', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '服务价格 VS 土地价格', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
					]
				},
				{
					id: 36,
					listType: 3,
					question: '生态因素重要性与重要程度判断',
					answers: [
						{ text: `环境污染 VS 水土流失`, options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: `环境污染 VS 景观破坏`, options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: `水土流失 VS 景观破坏`, options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
					]
				},
				{
					id: 37,
					listType: 3,
					question: '制度因素重要性与重要程度判断',
					answers: [
						{ text: '决策因素 VS 信息公开', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '决策因素 VS 政府诚信', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '决策因素 VS 公民参与', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '决策因素 VS 问责机制', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '决策因素 VS 风险管理', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '信息公开 VS 政府诚信', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '信息公开 VS 公民参与', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '信息公开 VS 问责机制', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '信息公开 VS 风险管理', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '政府诚信 VS 公民参与', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '政府诚信 VS 问责机制', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '政府诚信 VS 风险管理', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '公民参与 VS 问责机制', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '公民参与 VS 风险管理', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '问责机制 VS 风险管理', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
					]
				},
				{
					id: 38,
					listType: 3,
					question: '项目因素重要性与重要程度判断',
					answers: [
						{ text: '规划不当 VS 设计不当', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '规划不当 VS 进度紧张', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '规划不当 VS 技术不足', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '规划不当 VS 安全事故', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '规划不当 VS 工程质量', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '设计不当 VS 进度紧张', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '设计不当 VS 技术不足 ', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '设计不当 VS 安全事故 ', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '设计不当 VS 工程质量 ', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '进度紧张 VS 技术不足', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '进度紧张 VS 安全事故', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '进度紧张 VS 工程质量', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '技术不足 VS 安全事故', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '技术不足 VS 工程质量', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '安全事故 VS 工程质量', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
					]
				},
				{
					id: 39,
					listType: 3,
					question: '一级风险因素重要性与重要程度判断',
					answers: [
						{ text: '社会因素 VS 经济因素', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '社会因素 VS 生态因素', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '社会因素 VS 制度因素', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '社会因素 VS 项目因素', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '经济因素 VS 生态因素', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '经济因素 VS 制度因素', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '经济因素 VS 项目因素', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '生态因素 VS 制度因素', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '生态因素 VS 项目因素', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
						{ text: '制度因素 VS 项目因素', options1: [ '前者重要', '一样重要', '后者重要' ], options2: [ '同等重要', '稍微重要', '明显重要', '非常重要', '绝对重要' ] },
					]
				}
			]
		}
	]
};

export const skdidisoadad = {
	title: '“文明旅游同行 全心助力进博”',
	subTitle: '——朱家角古镇文明旅游调查问卷',
	titleSize: 16,
	intro: [
		'欢迎您来到江南水乡，朱家角古镇。感谢您抽出宝贵时间填写这份《朱家角古镇文明旅游调查问卷》，请根据您自身情况如实填写。此次问卷不记名，不会泄露您的个人信息。您的宝贵意见对我们的很重要，感谢您的配合。',
		'欢迎参加本次答题，共10题'
	],
	parts: [
		{
			title: '',
			intro: '',
			questions: [
				{
					id: 1,
					listType: 1,
					question: '您的性别',
					answers: [
						{ text: '男' }, { text: '女' },
					]
				},
				{
					id: 2,
					listType: 1,
					question: '您的年龄',
					answers: [
						{ text: '18周岁以下' }, { text: '18-25岁' }, { text: '25-35岁' }, { text: '35-45岁' }, { text: '45-55岁' }, { text: '55岁以上' }
					]
				},
				{
					id: 3,
					listType: 4,
					question: '请问您在外出游玩时目睹过哪些不文明行为',
					answers: [
						{ text: '随地吐痰、扔垃圾' }, { text: '随地大小便' }, { text: '破坏花草树木' }, { text: '无视禁止拍照标示' }, { text: '大声喧哗、插队' }, { text: '涂鸦' }, { text: '其他' }
					]
				},
				{
					id: 4,
					listType: 4,
					question: '您认为这些问题是什么原因造成的',
					answers: [
						{ text: '个人习惯' }, { text: '家庭教养' }, { text: '学校教育不到位' }, { text: '社会风气' }, { text: '监管不到位' }, { text: '从众心理作祟' }, { text: '公民素质' }, { text: '其他' }
					]
				},
				{
					id: 5,
					listType: 1,
					question: '对于一些不文明现象，您是否会上前制止',
					answers: [
						{ text: '会' }, { text: '不会' }, { text: '视情况而定' }
					]
				},
				{
					id: 6,
					listType: 1,
					question: '当附近没有垃圾桶，而您又觉得拿着垃圾很碍事，您会怎么做',
					answers: [
						{ text: '就地扔掉' }, { text: '直到看到垃圾桶再扔掉' }, { text: '视情况而定' }
					]
				},
				{
					id: 7,
					listType: 1,
					question: '就您自身而言，是否做到了文明出游',
					answers: [
						{ text: '大部门时候是' }, { text: '通常没有' }, { text: '视情况而定' }
					]
				},
				{
					id: 8,
					listType: 1,
					question: '出游时是否会对小伙伴的不文明行为进行制止',
					answers: [
						{ text: '会' }, { text: '不会' }, { text: '视情况而定' }
					]
				},
				{
					id: 9,
					listType: 4,
					question: '您认为应该如何改正不文明行为',
					answers: [
						{ text: '增强个人文明意识' }, { text: '健全管理机制，细化规章制度' }, { text: '加大惩罚力度' }, { text: '通过网络等新媒体加大文明宣传，营造氛围' }, { text: '其他' }
					]
				},
				{
					id: 10,
					listType: 4,
					question: '你在出游中最注重什么？',
					answers: [
						{ text: '景区特色' }, { text: '交通条件' }, { text: '服务设施' }, { text: '价格' }, { text: '安全条件' }, { text: '食宿条件' }, { text: '文化方面' }, { text: '其他' }
					]
				}
			]
		}
	]
};