/**
 * Created by jimmy on 2020/9/10.
 */
// 有效 array 判断
export const isValidArray = arr => {
	return arr && Array.isArray(arr) && arr.length > 0;
};

// 永久缓存 type = 1
// 当前会话缓存 type = 2 [default]
export const localCache = ({key = '', value = '', type = 2}) => {
	if(!key) {
		console.error('cache', 'key is required');
		return;
	}

	const storageAction = type == 1 ? window.localStorage : window.sessionStorage;
	if(value) {
		return storageAction.setItem(key, value);
	}else{
		return storageAction.getItem(key);
	}
};

// loading
export const loading = {
	show: () => {
		document.getElementById('loading').style.display = 'block';
	},
	hide: () => {
		document.getElementById('loading').style.display = 'none';
	}
};

export const getSearchByKey = (key, search = window.location.search) => {
	if(!search) return '';

	const _search = search.slice(1);
	const _searchArr = _search.split('&');
	const _map = {};
	_searchArr.map(item => {
		const _item = item.split('=');
		if(_item[0] && _item[1]) {
			_map[_item[0]] = _item[1];
		}
	});
	return _map[key] || '';
};

export const dialog = {
	toast: message => {
		const div = document.createElement('div');
		div.className = 'dialog-toast';
		div.innerText = message;
		document.body.appendChild(div);

		setTimeout(() => {
			div.style.opacity = 0;
		}, 2000);

		setTimeout(() => {
			document.body.removeChild(div);
		}, 3000);
	}
};