/**
 * Created by jimmy on 2020/9/10.
 */

import 'whatwg-fetch';
import * as Utils from './utils';
import * as CONST from './const';

export const getHeaders = () => {
	return {
		"Content-Type":"application/json; charset=utf-8",
		"Sys-Auth": CONST.sysToken
	}
};

export const post = async (settings = {}) => {
	let url = CONST.apiPrefix + settings.url;

	Utils.loading.show();
	let response = await window.fetch(url, {
		method: 'POST',
		headers: getHeaders(),
		body: JSON.stringify(settings.data || {})
	});
	Utils.loading.hide();

	response = await response.json();
	if(response.ok != 0) {
		Utils.dialog.toast(response.msg);
		return false;
	}
	return response;
};

export const get = async (settings = {}) => {
	let url = CONST.apiPrefix + settings.url;

	if(settings.data) {
		let queryArr = [];
		for(let i in settings.data) {
			if(i && settings.data[i]){
				queryArr.push(`${i}=${settings.data[i]}`);
			}
		}
		url += '?' + queryArr.join('&');
	}

	Utils.loading.show();
	let response = await window.fetch(url, {
		method: 'GET',
		headers: getHeaders()
	});
	Utils.loading.hide();

	response = await response.json();
	if(response.ok != 0) {
		Utils.dialog.toast(response.msg);
		return false;
	}
	return response;
};