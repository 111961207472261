import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';

import Home from './page/home';

const App = () => {
	return (
		<Router>
			<div>
				<Route exact path="/:prj" component={Home} />
			</div>
		</Router>
	);
};

export default App;